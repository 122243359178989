import { formatEther, hexToBigInt, parseEther } from "viem";
import { env } from "./utils/envs";

const SECONDS_IN_YEAR = 31536000;
const BLOCK_TIME_SECONDS = 12;
export const BLOCKS_IN_A_YEAR = SECONDS_IN_YEAR / BLOCK_TIME_SECONDS;
export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";
export const MAX_UINT256 = hexToBigInt(
  "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff",
);

const stakeReqPricing: `${number}`[] = [
  "275000",
  "66000",
  "22000",
  "2200",
  "880",
  "440",
  "275",
  "165",
  "110",
  "55",
  "16",
  "5",
  "2",
];

export const getStakeReq = (
  name: string,
  _namespace: string | bigint,
  referrer?: boolean,
): bigint => {
  if (name.length < 1) return 0n;
  if (name.length > 13) return parseEther("1");
  let req = stakeReqPricing[name.length - 1];
  if (referrer) {
    req = Math.ceil(Number(req) * 0.9).toString() as `${number}`;
  }
  return parseEther(req);
};

export const bigIntMax = (...args: bigint[]) =>
  args.reduce((m, e) => (e > m ? e : m));
export const bigIntMin = (...args: bigint[]) =>
  args.reduce((m, e) => (e < m ? e : m));

export const formatTokens = (
  amount: bigint | undefined,
  decimalsDisplay = 2,
  round = false,
) => {
  const str = formatEther(amount ?? 0n);
  if (round) {
    return Number(Number(str).toFixed(decimalsDisplay)).toString();
  }
  return str.substring(
    0,
    str.indexOf(".") >= 0 ? str.indexOf(".") + 1 + decimalsDisplay : undefined,
  );
};

export const formatKai = (amount: bigint | undefined, round = false) => {
  return Number(formatTokens(amount ?? 0n, 8, round)).toString();
};

export const compareArrays = (a: readonly any[], b: readonly any[]) =>
  a.length === b.length && a.every((element, index) => element === b[index]);

export const getBaseApiURL = (chainId?: number) => {
  return (
    env.REACT_APP_API_BASE_URLS[chainId ?? env.REACT_APP_DEFAULT_CHAIN_ID] ??
    env.REACT_APP_API_BASE_URLS[env.REACT_APP_DEFAULT_CHAIN_ID]
  );
};
