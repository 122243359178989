import { TOKENS } from "../tokens";
import { Stack, Typography } from "@mui/material";
import { formatKai } from "../helper";

type Props = {
  amount: bigint | undefined;
  round?: boolean;
};

export default function KaiTokenAmount({ amount, round }: Props) {
  return (
    <Stack sx={{ flexDirection: "row", gap: 1, alignItems: "center" }}>
      <Typography>{formatKai(amount, round)}</Typography>
      <img src={TOKENS.KAI.image} alt="KAI token logo" width={24} height={24} />
    </Stack>
  );
}
