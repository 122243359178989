import { Breakpoint, Button, Stack, Typography } from "@mui/material";
import { useWriteContract } from "wagmi";
import {
  useSimulateShinkaiRegistryClaimDelegationRewards,
  useSimulateShinkaiRegistryClaimRewards,
  useSimulateShinkaiRegistryClaimStakingRewards,
} from "../generated";
import KaiTokenAmount from "./KaiTokenAmount";
import { BLOCKS_IN_A_YEAR } from "../helper";
import { formatEther } from "viem";
import APRChip from "./APRChip";
import { useGetBaseRewardsRate } from "../hooks/useGetBaseRewardsRate";
import { useSnackbar } from "notistack";
import { ButtonMessage, SnackbarMessage } from "../utils/texts";
import { useEffect } from "react";
import { useGetUserKaiTokenBalance } from "../hooks/useGetUserKaiTokenBalance";
import { useQueryClient } from "@tanstack/react-query";
import useWaitForTransactionReceipt from "../hooks/useWaitForTransactionReceipt";
import { getShinkaiRegistryAddress } from "../contracts";

type Props = {
  identity: string;
  isOwner: boolean;
  isClaimed: boolean;
  stakedTokens: string;
  delegatedTokens?: string;
};

const breakpoint: Breakpoint = "md";

function RewardsRow({
  accruedLabel,
  accruedValue,
  apr,
  balanceLabel,
  balanceValue,
  claimButton,
  yearly,
}: {
  accruedLabel: string;
  accruedValue?: bigint;
  apr: number;
  balanceLabel: string;
  balanceValue?: bigint;
  claimButton: JSX.Element | false;
  yearly?: bigint;
}) {
  return (
    <Stack
      sx={{
        flexDirection: { xs: "column", [breakpoint]: "row", width: "100%" },
        gap: 2,
        justifyContent: { [breakpoint]: "space-between" },
        alignItems: "center",
      }}
    >
      <Stack sx={{ flexDirection: "row", gap: 2, alignItems: "center" }}>
        {claimButton}
        <Stack
          direction="row"
          sx={{
            gap: 1,
            height: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="body1">{accruedLabel}</Typography>
          <Typography component="div">
            <KaiTokenAmount amount={accruedValue} />
          </Typography>
        </Stack>
      </Stack>
      <Stack sx={{ flexDirection: "row", gap: 2 }}>
        <Stack
          sx={{
            flexDirection: "row",
            gap: 2,
            alignItems: "center",
          }}
        >
          <Stack
            direction="row"
            sx={{
              gap: 1,
              width: "100%",
              height: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="body1">{balanceLabel}</Typography>
            <Typography component="div">
              <KaiTokenAmount amount={balanceValue} />
            </Typography>
          </Stack>
          <APRChip aprValue={apr} />
        </Stack>
        <Stack
          direction="row"
          sx={{
            gap: 1,
            justifyContent: { xs: "end", [breakpoint]: "space-between" },
            alignItems: "center",
          }}
        >
          <Typography variant="body2">Yearly:</Typography>
          <Typography component="div">
            <KaiTokenAmount amount={yearly} round />
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default function IdentityRewardsForm({
  identity,
  isOwner,
  isClaimed,
  stakedTokens,
  delegatedTokens,
}: Props) {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: dataStakingRewards,
    refetch: refetchStakingRewards,
    error: errorStakingRewards,
  } = useSimulateShinkaiRegistryClaimStakingRewards({
    address: getShinkaiRegistryAddress(),
    args: [identity],
  });
  const {
    data: dataDelegationRewards,
    refetch: refetchDelegationRewards,
    error: errorDelegationRewards,
  } = useSimulateShinkaiRegistryClaimDelegationRewards({
    address: getShinkaiRegistryAddress(),
    args: [identity],
  });
  const { data: dataClaimAllRewards } = useSimulateShinkaiRegistryClaimRewards({
    address: getShinkaiRegistryAddress(),
    args: [identity],
  });
  const { queryKey: kaiBalanceQueryKey } = useGetUserKaiTokenBalance();
  const { data: baseRewardsRate } = useGetBaseRewardsRate();

  const { writeContract, data: hash, isPending } = useWriteContract();
  const {
    isLoading,
    error: errorTransactionReceipt,
    isSuccess: isSuccessTransactionReceipt,
  } = useWaitForTransactionReceipt({
    hash,
  });

  useEffect(() => {
    if (isSuccessTransactionReceipt) {
      enqueueSnackbar({
        message: SnackbarMessage.RewardsClaimSuccess,
        variant: "success",
      });
      refetchStakingRewards();
      refetchDelegationRewards();
      queryClient.invalidateQueries({ queryKey: kaiBalanceQueryKey });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessTransactionReceipt]);

  useEffect(() => {
    if (
      errorStakingRewards ||
      errorDelegationRewards ||
      errorTransactionReceipt
    ) {
      enqueueSnackbar({
        message: `Error: ${
          (errorStakingRewards ||
            errorDelegationRewards ||
            errorTransactionReceipt)!.message
        }`,
        variant: "error",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorStakingRewards, errorDelegationRewards, errorTransactionReceipt]);

  useEffect(() => {
    if (isLoading) {
      enqueueSnackbar({
        message: SnackbarMessage.TransactionSubmitted,
        variant: "info",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  function handleClaimStakingRewardsButtonClick() {
    writeContract(dataStakingRewards!.request);
  }

  function handleClaimDelegationRewardsButtonClick() {
    writeContract(dataDelegationRewards!.request);
  }

  function handleClaimAllRewardsButtonClick() {
    writeContract(dataClaimAllRewards!.request);
  }

  const delegationsAPRBN = baseRewardsRate
    ? BigInt(baseRewardsRate.rate) * BigInt(BLOCKS_IN_A_YEAR)
    : 0n;
  const delegationsAPR = Number(formatEther(delegationsAPRBN));

  const stakingAPRBN = delegationsAPRBN * 2n;
  const stakingAPR = Number(formatEther(stakingAPRBN));
  return (
    <Stack
      sx={{
        gap: 2,
        width: "100%",
        alignItems: { xs: "center", [breakpoint]: "start" },
      }}
    >
      <RewardsRow
        accruedLabel="Accrued staking rewards:"
        accruedValue={dataStakingRewards?.result}
        apr={stakingAPR}
        balanceLabel="Staked tokens:"
        balanceValue={BigInt(stakedTokens)}
        yearly={(BigInt(stakedTokens) * stakingAPRBN) / BigInt(1e18)}
        claimButton={
          isOwner && (
            <Button
              variant={"outlined"}
              onClick={handleClaimStakingRewardsButtonClick}
              disabled={isLoading || isPending}
            >
              {isPending
                ? ButtonMessage.ConfirmTx
                : isLoading
                ? ButtonMessage.PendingTx
                : "Claim"}
            </Button>
          )
        }
      />
      <RewardsRow
        accruedLabel="Accrued delegation rewards:"
        accruedValue={isClaimed ? dataDelegationRewards?.result : 0n}
        apr={delegationsAPR}
        balanceLabel="Delegated tokens:"
        balanceValue={BigInt(delegatedTokens ?? "0")}
        yearly={
          (BigInt(delegatedTokens ?? "0") * delegationsAPRBN) / BigInt(1e18)
        }
        claimButton={
          isOwner && (
            <Button
              variant={"outlined"}
              onClick={handleClaimDelegationRewardsButtonClick}
              disabled={isLoading || isPending}
            >
              {isPending
                ? ButtonMessage.ConfirmTx
                : isLoading
                ? ButtonMessage.PendingTx
                : "Claim"}
            </Button>
          )
        }
      />
      {isOwner && (
        <Button
          variant={isLoading || isPending ? "outlined" : "contained"}
          onClick={handleClaimAllRewardsButtonClick}
          disabled={isLoading || isPending}
        >
          {isPending
            ? ButtonMessage.ConfirmTx
            : isLoading
            ? ButtonMessage.PendingTx
            : "Claim all rewards"}
        </Button>
      )}
    </Stack>
  );
}
