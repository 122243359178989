import { Container, Stack, Typography } from "@mui/material";
import IdentityList from "../components/IdentityList";

export default function AllIdentitiesPage() {
  return (
    <>
      <Container>
        <Stack sx={{ alignItems: "center", my: 4, gap: 4 }}>
          <Typography variant="h2" component="h1" sx={{ textAlign: "center" }}>
            Protocol Identities
          </Typography>
          <IdentityList />
        </Stack>
      </Container>
    </>
  );
}
