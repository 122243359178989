"use client";
import Button from "@mui/material/Button";
import { useWriteContract } from "wagmi";
import { useSimulateShinkaiTokenApprove } from "../generated";
import { ButtonMessage, SnackbarMessage } from "../utils/texts";
import { MAX_UINT256 } from "../helper";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import useWaitForTransactionReceipt from "../hooks/useWaitForTransactionReceipt";
import {
  getShinkaiRegistryAddress,
  getShinkaiTokenAddress,
} from "../contracts";

type Props = {
  amount?: bigint;
  callbackOnSuccess: () => void;
  disabled: boolean;
  size?: "small" | "large" | "medium";
};

export default function ApproveKaiButton({
  amount,
  callbackOnSuccess,
  disabled,
  size = "large",
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { data } = useSimulateShinkaiTokenApprove({
    address: getShinkaiTokenAddress(),
    args: [getShinkaiRegistryAddress(), amount ?? MAX_UINT256],
    query: { enabled: !disabled },
  });
  const { writeContract, data: hash, isPending } = useWriteContract();
  const { isLoading, isSuccess } = useWaitForTransactionReceipt({
    hash,
  });

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar({
        message: SnackbarMessage.ApproveSuccess,
        variant: "success",
      });
      callbackOnSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (isLoading) {
      enqueueSnackbar({
        message: SnackbarMessage.TransactionSubmitted,
        variant: "info",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  function approveKaiForRegistry() {
    writeContract(data!.request);
  }

  const buttonDisabled = disabled || isLoading || isPending;

  return (
    <Button
      variant={buttonDisabled ? "outlined" : "contained"}
      size={size}
      onClick={approveKaiForRegistry}
      disabled={buttonDisabled}
      sx={{ justifySelf: "center" }}
    >
      {isPending
        ? ButtonMessage.ConfirmTx
        : isLoading
        ? ButtonMessage.PendingTx
        : `Approve KAI`}
    </Button>
  );
}
