import { useAccount } from "wagmi";
import { useGetProfileData } from "../hooks/useGetProfileData";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import KaiTokenAmount from "./KaiTokenAmount";
import { CircularProgress, Divider, Typography } from "@mui/material";
import { useGetUserKaiTokenBalance } from "../hooks/useGetUserKaiTokenBalance";

export default function ProfileSummary() {
  const { address } = useAccount();
  const { data: profileData, isLoading: isLoadingProfileData } =
    useGetProfileData(address ?? "");
  const { data: kaiTokenBalance, isLoading: isLoadingTokenBalance } =
    useGetUserKaiTokenBalance();
  const walletTokens = kaiTokenBalance ?? 0n;
  const stakedTokens = profileData?.stakedTokens;
  const totalTokens =
    stakedTokens != null ? walletTokens + stakedTokens : undefined;

  return (
    <Grid container spacing={2}>
      <Grid
        xs={4}
        sm={6}
        sx={{ display: "flex", justifyContent: "end", textAlign: "end" }}
      >
        Your address:
      </Grid>
      <Grid xs={8} sm={6}>
        <Typography
          sx={{
            display: "flex",
            justifyContent: "start",
            overflowWrap: "anywhere",
            textAlign: "start",
          }}
        >
          {address}
        </Typography>
      </Grid>
      <Grid
        xs={4}
        sm={6}
        sx={{ display: "flex", justifyContent: "end", textAlign: "end" }}
      >
        KAI in wallet:
      </Grid>
      <Grid xs={8} sm={6} sx={{ textAlign: "start" }}>
        {isLoadingTokenBalance ? (
          "..."
        ) : (
          <KaiTokenAmount amount={walletTokens} />
        )}
      </Grid>
      <Grid
        sm={6}
        xs={4}
        sx={{ display: "flex", justifyContent: "end", textAlign: "end" }}
      >
        KAI staked in identities:
      </Grid>
      <Grid sm={6} xs={8} sx={{ textAlign: "start" }}>
        {isLoadingProfileData ? (
          "..."
        ) : stakedTokens != null ? (
          <KaiTokenAmount amount={stakedTokens} />
        ) : (
          "Error"
        )}
      </Grid>
      <Grid sm={12} sx={{ p: 0, display: "grid" }}>
        <Divider
          sx={{ maxWidth: "576px", justifySelf: "center", width: "100%" }}
        />
      </Grid>
      <Grid
        sm={6}
        xs={4}
        sx={{ display: "flex", justifyContent: "end", textAlign: "end" }}
      >
        Total KAI:
      </Grid>
      <Grid sm={6} xs={8} sx={{ textAlign: "start" }}>
        {isLoadingProfileData ? (
          "..."
        ) : totalTokens != null ? (
          <KaiTokenAmount amount={totalTokens} />
        ) : (
          "Error"
        )}
      </Grid>
    </Grid>
  );
}
