"use client";
import Button from "@mui/material/Button";
import { useSimulateShinkaiRegistryUnclaimIdentity } from "../generated";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { ButtonMessage, SnackbarMessage } from "../utils/texts";
import { useEffect, useState } from "react";
import { useModal } from "mui-modal-provider";
import UnclaimIdentityDialog from "../dialogs/UnclaimIdentityDialog";
import useWaitForTransactionReceipt from "../hooks/useWaitForTransactionReceipt";
import { getShinkaiRegistryAddress } from "../contracts";

type Props = {
  identity: string;
};

export default function UnclaimIdentityButton({ identity }: Props) {
  const navigate = useNavigate();
  const { showModal } = useModal();
  const [hash, setHash] = useState<`0x${string}`>();
  const { enqueueSnackbar } = useSnackbar();
  const { data } = useSimulateShinkaiRegistryUnclaimIdentity({
    address: getShinkaiRegistryAddress(),
    args: [identity],
  });
  const { isLoading: isLoadingTxReceipt, isSuccess } =
    useWaitForTransactionReceipt({
      hash,
    });

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar({
        message: SnackbarMessage.UnclaimSuccess,
        variant: "success",
      });
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  function unclaimIdentity() {
    if (!data) return;
    const modal = showModal(UnclaimIdentityDialog, {
      identity,
      txRequest: data.request,
      onTxSubmit: (txHash) => {
        setHash(txHash);
      },
      onCancel: () => {
        modal.hide();
      },
    });
  }

  return (
    <Button
      variant="outlined"
      size="large"
      sx={{ my: 2 }}
      onClick={unclaimIdentity}
      disabled={isLoadingTxReceipt}
    >
      {isLoadingTxReceipt ? ButtonMessage.PendingTx : `Delete ${identity}`}
    </Button>
  );
}
