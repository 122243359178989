import { Chip } from "@mui/material";

type Props = {
  aprValue: number;
};

export default function APRChip({ aprValue }: Props) {
  const aprPercent = (Math.round(aprValue * 10000) / 100).toFixed(2);
  const label = `APR ${aprPercent} %`;
  return <Chip label={label} />;
}
