"use client";
import { useAccount } from "wagmi";
import { useReadShinkaiTokenBalanceOf } from "../generated";
import { getShinkaiTokenAddress } from "../contracts";

export const useGetUserKaiTokenBalance = () => {
  const { address } = useAccount();
  return useReadShinkaiTokenBalanceOf({
    address: getShinkaiTokenAddress(),
    args: [address!],
    query: {
      enabled: address !== undefined,
      staleTime: 60000,
    },
  });
};
