import { Stack, Typography } from "@mui/material";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { PropsWithChildren } from "react";
import { useAccount, useConfig } from "wagmi";

export default function IsConnectedWrapper({ children }: PropsWithChildren) {
  const { isConnected, chain } = useAccount();
  const { chains } = useConfig();
  const usingSupportedNetwork = chains.some(
    (_chain) => _chain.id === chain?.id,
  );
  return (
    <>
      {isConnected && usingSupportedNetwork ? (
        <>{children}</>
      ) : (
        <Stack sx={{ my: 4, gap: 2, alignItems: "center" }}>
          <Typography>
            Connect your wallet to get started with using the Shinkai dApp
          </Typography>
          <ConnectButton />
        </Stack>
      )}
    </>
  );
}
