import { Button, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { formatEther, parseEther } from "viem";
import {
  useSimulateShinkaiRegistryDecreaseStake,
  useSimulateShinkaiRegistryIncreaseStake,
} from "../generated";
import { useWriteContract } from "wagmi";
import { bigIntMax, getStakeReq } from "../helper";
import ApproveKaiButton from "./ApproveKaiButton";
import { useDebounce } from "usehooks-ts";
import KaiTokenAmount from "./KaiTokenAmount";
import { useSnackbar } from "notistack";
import { ButtonMessage, SnackbarMessage } from "../utils/texts";
import { useGetUserKaiTokenBalance } from "../hooks/useGetUserKaiTokenBalance";
import { useQueryClient } from "@tanstack/react-query";
import { useGetUserKaiTokenAllowance } from "../hooks/useGetUserKaiTokenAllowance";
import useWaitForTransactionReceipt from "../hooks/useWaitForTransactionReceipt";
import { getShinkaiRegistryAddress } from "../contracts";

type Props = {
  stakedAmount: bigint;
  identity: string;
  name: string;
  namespace: bigint;
  isOwner: boolean;
  refetchIdentityData: () => void;
};

export default function IdentityStakedAmountForm({
  stakedAmount,
  identity,
  name,
  namespace,
  isOwner,
  refetchIdentityData,
}: Props) {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [editing, setEditing] = useState(false);
  const [stake, setStake] = useState(formatEther(stakedAmount));
  const [stakeBN, setStakeBN] = useState(stakedAmount);
  const debouncedStakeBN = useDebounce(stakeBN, 500);
  const [increaseStakeSettled, setIncreaseStakeSettled] = useState(false);
  const [decreaseStakeSettled, setDecreaseStakeSettled] = useState(false);

  const { data: shinTokenAllowance, queryKey: kaiTokenAllowanceQueryKey } =
    useGetUserKaiTokenAllowance(getShinkaiRegistryAddress());

  const { data: shinTokenBalance, queryKey: kaiBalanceQueryKey } =
    useGetUserKaiTokenBalance();

  const stakeReq = getStakeReq(name, namespace);

  const {
    data: increaseStakeData,
    error: errorIncreaseStake,
    isSuccess: isSuccessSimulateIncrease,
    isFetching: isFetchingSimulateIncrease,
  } = useSimulateShinkaiRegistryIncreaseStake({
    address: getShinkaiRegistryAddress(),
    args: [identity, bigIntMax(0n, debouncedStakeBN - stakedAmount)],
    query: {
      enabled:
        isOwner &&
        debouncedStakeBN >= stakeReq &&
        debouncedStakeBN >= stakedAmount &&
        debouncedStakeBN - stakedAmount <= (shinTokenAllowance ?? 0),
    },
  });

  useEffect(() => {
    setIncreaseStakeSettled(!!isSuccessSimulateIncrease);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessSimulateIncrease, isFetchingSimulateIncrease]);
  const { writeContract, data: hash, isPending } = useWriteContract();

  const {
    data: decreaseStakeData,
    error: errorDecreaseStake,
    isSuccess: isSuccessSimulateDecrease,
    isFetching: isFetchingSimulateDecrease,
  } = useSimulateShinkaiRegistryDecreaseStake({
    address: getShinkaiRegistryAddress(),
    args: [name, namespace, bigIntMax(0n, stakedAmount - debouncedStakeBN)],
    query: {
      enabled:
        isOwner &&
        debouncedStakeBN >= stakeReq &&
        debouncedStakeBN <= stakedAmount,
    },
  });

  useEffect(() => {
    setDecreaseStakeSettled(!!isSuccessSimulateDecrease);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessSimulateDecrease, isFetchingSimulateDecrease]);

  const {
    isLoading,
    error: errorTransactionReceipt,
    isSuccess: isSuccessTransactionReceipt,
  } = useWaitForTransactionReceipt({
    hash,
  });

  useEffect(() => {
    if (errorIncreaseStake || errorDecreaseStake || errorTransactionReceipt) {
      enqueueSnackbar({
        message: `Error: ${
          (errorIncreaseStake || errorDecreaseStake || errorTransactionReceipt)!
            .message
        }`,
        variant: "error",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorIncreaseStake, errorDecreaseStake, errorTransactionReceipt]);

  useEffect(() => {
    if (isLoading) {
      enqueueSnackbar({
        message: SnackbarMessage.TransactionSubmitted,
        variant: "info",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (isSuccessTransactionReceipt) {
      enqueueSnackbar({
        message: SnackbarMessage.StakeChangeSuccess,
        variant: "success",
      });
      queryClient.invalidateQueries({ queryKey: kaiBalanceQueryKey });
      queryClient.invalidateQueries({ queryKey: kaiTokenAllowanceQueryKey });
      refetchIdentityData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessTransactionReceipt]);

  function handleEditingButtonClick() {
    if (editing) {
      if (stakeBN > stakedAmount) {
        writeContract(increaseStakeData!.request);
      } else if (stakeBN < stakedAmount) {
        writeContract(decreaseStakeData!.request);
      }
    }
    setEditing(!editing);
  }

  function handleStakeInputChanged(value: string) {
    let newValue = stake;
    setIncreaseStakeSettled(false);
    setDecreaseStakeSettled(false);
    try {
      const newValueBN = parseEther(value as any);
      setIncreaseStakeSettled(newValueBN === debouncedStakeBN);
      setDecreaseStakeSettled(newValueBN === debouncedStakeBN);
      setStakeBN(newValueBN);
      newValue = value;
    } catch (e) {}
    setStake(newValue);
  }

  const stakeError = stakeBN < stakeReq;
  const balanceError = (shinTokenBalance ?? 0) < stakeBN - stakedAmount;

  const inputHasError = stakeError || balanceError;

  const editOrSaveButtonDisabled =
    (editing && inputHasError) ||
    isPending ||
    isLoading ||
    (editing && !increaseStakeSettled && !decreaseStakeSettled);

  return (
    <Stack sx={{ gap: 2, alignSelf: "start", width: "100%" }}>
      {editing ? (
        <TextField
          color="secondary"
          label="KAI tokens stake amount"
          onChange={(e) => handleStakeInputChanged(e.target.value)}
          value={stake}
          error={inputHasError}
          helperText={
            balanceError
              ? "Stake amount exceeds your KAI balance"
              : `Minimum stake requirement for this identity: ${formatEther(
                  stakeReq,
                )}`
          }
        />
      ) : (
        <Stack
          sx={{ height: "78px", flexDirection: "row", alignItems: "center" }}
        >
          <Stack direction="row" gap={1}>
            <Typography variant="h6">Staked KAI:</Typography>
            <KaiTokenAmount amount={stakeBN} />
          </Stack>
        </Stack>
      )}
      {isOwner && (
        <Stack direction="row" gap={2}>
          {(shinTokenAllowance ?? 0) < stakeBN - stakedAmount &&
          editing &&
          stakeBN !== stakedAmount ? (
            <ApproveKaiButton
              amount={stakeBN - stakedAmount}
              callbackOnSuccess={() => {
                queryClient.invalidateQueries({
                  queryKey: kaiTokenAllowanceQueryKey,
                });
              }}
              disabled={inputHasError}
              size="medium"
            />
          ) : (
            <Button
              variant={editOrSaveButtonDisabled ? "outlined" : "contained"}
              onClick={handleEditingButtonClick}
              disabled={editOrSaveButtonDisabled}
            >
              {editing
                ? increaseStakeSettled || decreaseStakeSettled || inputHasError
                  ? "Save"
                  : ButtonMessage.PreparingTx
                : isPending
                ? ButtonMessage.ConfirmTx
                : isLoading
                ? ButtonMessage.PendingTx
                : "Edit"}
            </Button>
          )}
          {editing && (
            <Button
              variant="outlined"
              onClick={() => {
                setEditing(false);
                setStake(formatEther(stakedAmount));
                setStakeBN(stakedAmount);
              }}
            >
              Cancel
            </Button>
          )}
        </Stack>
      )}
    </Stack>
  );
}
