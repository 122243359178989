"use client";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useAccount } from "wagmi";
import IdentityDelegationsForm from "../components/IdentityDelegationsForm";
import { useParams } from "react-router-dom";
import { useGetIdentityData } from "../hooks/useGetIdentityData";
import { useState } from "react";
import IdentityRewardsTabContent from "../components/IdentityRewardsTabContent";
import IdentityInfoTabContent from "../components/IdentityInfoTabContent";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`identity-tabpanel-${index}`}
      aria-labelledby={`identity-tab-${index}`}
      style={{ width: "100%" }}
      {...other}
    >
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `identity-tab-${index}`,
    "aria-controls": `identity-tabpanel-${index}`,
  };
}

export default function IdentityPage() {
  const [tabIndex, setTabIndex] = useState(0);
  let { identity } = useParams();
  if (!identity) {
    identity = "";
  }

  const { address } = useAccount();
  const {
    data: identityData,
    isLoading,
    refetch,
    error,
  } = useGetIdentityData(identity);
  const isOwner =
    identityData?.nft.owner.toLowerCase() === address?.toLowerCase();

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <>
      <Container>
        <Stack alignItems={"center"} mb={1}>
          <Stack sx={{ my: 4, gap: 1, alignItems: "center" }}>
            <Typography
              variant="h2"
              component="h1"
              sx={{ overflowWrap: "anywhere", textAlign: "center" }}
            >
              @@{identity}
            </Typography>
            {isOwner && (
              <Button
                variant="text"
                onClick={() => {
                  navigator.clipboard.writeText(
                    `${window.location.host}/?referrer=${identity}`,
                  );
                }}
              >
                Copy referral link
              </Button>
            )}
          </Stack>
          {isLoading && <CircularProgress />}
          {error ? (
            <Typography>
              Error occurred when fetching user's identity list.
            </Typography>
          ) : identityData === null ? (
            <Stack sx={{ alignItems: "center", alignText: "center" }}>
              <Typography>Identity is not registered.</Typography>
              <Typography>
                If you've just registered this identity, please wait a few
                seconds.
              </Typography>
            </Stack>
          ) : (
            identityData && (
              <>
                <Tabs
                  value={tabIndex}
                  onChange={handleTabChange}
                  aria-label="identity tabs"
                >
                  <Tab label="Info" {...a11yProps(0)} />
                  <Tab label="Rewards" {...a11yProps(1)} />
                  <Tab label="Delegations" {...a11yProps(2)} />
                </Tabs>
                <CustomTabPanel value={tabIndex} index={0}>
                  <IdentityInfoTabContent
                    identityData={identityData}
                    refetch={refetch}
                  />
                </CustomTabPanel>
                <CustomTabPanel value={tabIndex} index={1}>
                  <IdentityRewardsTabContent identityData={identityData} />
                </CustomTabPanel>
                <CustomTabPanel value={tabIndex} index={2}>
                  <IdentityDelegationsForm
                    identity={identity}
                    stakedAmount={BigInt(identityData.stakedTokens)}
                    delegations={identityData.delegations.map((del) => {
                      return {
                        delegatee: {
                          name: del.delegatee.substring(
                            0,
                            del.delegatee.lastIndexOf("."),
                          ),
                          namespace: del.delegatee.substring(
                            del.delegatee.lastIndexOf("."),
                          ),
                        },
                        amount: BigInt(del.amount),
                      };
                    })}
                    delegatedToThis={BigInt(
                      identityData.delegatedTokens ?? "0",
                    )}
                    isOwner={isOwner}
                    refetch={refetch}
                  />
                </CustomTabPanel>
              </>
            )
          )}
        </Stack>
      </Container>
    </>
  );
}
