"use client";
import { IdentityListType } from "../types";
import { keepPreviousData, useInfiniteQuery } from "@tanstack/react-query";
import QueryKey from "../utils/queryKeys";
import { gql } from "@urql/core";
import { useUrqlClient } from "./useUrqlClient";
import { useAccount } from "wagmi";

type QueryResponse = {
  shinkaiIdentities: IdentityListType[];
};

// Purposefully not inlined in the queries to keep them static
const pageSize = 10;

const AllIdentitiesQuery = gql<
  QueryResponse,
  { filter: string; pageParam: string }
>`
  query ($filter: String!, $pageParam: String!) {
    shinkaiIdentities(
      first: 10
      orderBy: identityRaw
      where: {
        and: [
          { identityRaw_contains_nocase: $filter }
          { identityRaw_gt: $pageParam }
        ]
      }
    ) {
      identityRaw
      stakedTokens
    }
  }
`;

const UserIdentitiesQuery = gql<
  QueryResponse,
  { filter: string; pageParam: string; owner: string }
>`
  query ($filter: String!, $pageParam: String!, $owner: String!) {
    shinkaiIdentities(
      first: 10
      orderBy: identityRaw
      where: {
        and: [
          { identityRaw_contains_nocase: $filter }
          { identityRaw_gt: $pageParam }
          { nft_: { owner: $owner } }
        ]
      }
    ) {
      identityRaw
      stakedTokens
    }
  }
`;

export const useGetIdentities = ({
  filter,
  owner,
}: {
  filter?: string;
  owner?: string;
}) => {
  const { data: urqlData } = useUrqlClient();
  filter = filter?.trim() ?? "";

  return useInfiniteQuery({
    queryKey: [QueryKey.IDENTITIES, { urqlData, filter, owner }],
    queryFn: async ({ pageParam }) => {
      const urqlClient = urqlData?.client;
      if (!urqlClient) return null;
      const result = owner
        ? await urqlClient.query(UserIdentitiesQuery, {
            filter,
            pageParam,
            owner,
          })
        : await urqlClient.query(AllIdentitiesQuery, {
            filter,
            pageParam,
          });
      return result.data?.shinkaiIdentities ?? null;
    },
    initialPageParam: "",
    getNextPageParam: (lastPage, pages) =>
      lastPage && lastPage.length < pageSize
        ? null
        : lastPage?.[lastPage.length - 1]?.identityRaw ?? null,
    placeholderData: keepPreviousData,
    staleTime: 60000,
  });
};
