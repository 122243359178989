import { Stack, Divider, Button, Typography } from "@mui/material";
import { ZERO_ADDRESS, getStakeReq } from "../helper";
import { Identity } from "../types";
import { useAccount } from "wagmi";
import { addNFTToWallet } from "../tokens";
import { IdentityDataInfoForm } from "./IdentityDataInfoForm";
import IdentityStakedAmountForm from "./IdentityStakedAmountForm";
import UnclaimIdentityButton from "./UnclaimIdentityButton";
import { useGetShinkaiNamespaces } from "../hooks/useGetShinkaiNamespaces";

type Props = {
  identityData: Identity;
  refetch: () => void;
};

export default function IdentityInfoTabContent({
  identityData,
  refetch,
}: Props) {
  const { address } = useAccount();
  const { data: shinkaiNamespaces } = useGetShinkaiNamespaces();
  const isOwner = identityData?.nft.owner.toLowerCase() === address?.toLowerCase();
  const tokenId = identityData?.nft.tokenId ?? "";

  const identity = identityData.identityRaw;
  const identityName = identity.substring(0, identity.lastIndexOf("."));
  const namespaceString = identity.substring(identity.lastIndexOf("."));
  const namespace = shinkaiNamespaces[namespaceString];

  return (
    <>
      <Stack width="100%" divider={<Divider />} gap={2}>
        <Stack gap={2}>
          <Stack
            sx={{
              flexDirection: "row",
              gap: 1,
              alignItems: "center",
            }}
          >
            <Typography variant="h6">Owner:</Typography>
            <Typography sx={{ overflowWrap: "anywhere" }}>
              {identityData.nft.owner === ZERO_ADDRESS
                ? "None"
                : identityData.nft.owner}
            </Typography>
          </Stack>
          <Stack direction={"row"} gap={2} alignItems={"center"}>
            <Stack
              sx={{
                flexDirection: "row",
                gap: 1,
                alignItems: "center",
              }}
            >
              <Typography variant="h6">NFT ID:</Typography>
              <Typography>{tokenId}</Typography>
            </Stack>
            {isOwner && (
              <Button
                onClick={() => {
                  addNFTToWallet(tokenId);
                }}
              >
                Add to wallet
              </Button>
            )}
          </Stack>
        </Stack>
        <IdentityDataInfoForm
          identityData={identityData}
          isOwner={isOwner}
          refetchIdentityData={refetch}
        />
        <IdentityStakedAmountForm
          stakedAmount={BigInt(identityData.stakedTokens)}
          identity={identityData.identityRaw}
          name={identityName}
          namespace={namespace}
          isOwner={isOwner}
          refetchIdentityData={refetch}
        />
      </Stack>
      <Stack alignItems="center">
        {isOwner && (
          <UnclaimIdentityButton identity={identityData.identityRaw} />
        )}
      </Stack>
    </>
  );
}
