"use client";
import { ClaimHistory } from "../types";
import QueryKey from "../utils/queryKeys";
import { useQuery } from "@tanstack/react-query";
import { useUrqlClient } from "./useUrqlClient";
import { gql } from "@urql/core";

type QueryResponse = {
  shinkaiIdentities: {
    rewardClaims: ClaimHistory[];
  }[];
};

const Query = gql<QueryResponse, { identity: string }>`
  query ($identity: ID!) {
    shinkaiIdentities(where: { identityRaw: $identity }) {
      rewardClaims(orderBy: timestamp, orderDirection: desc) {
        timestamp
        type
        amount
      }
    }
  }
`;

export const useGetIdentityClaimHistory = (identity: string) => {
  const { data: urqlData } = useUrqlClient();

  return useQuery({
    queryKey: [QueryKey.CLAIM_HISTORY, { urqlData, identity }],
    queryFn: async () => {
      const urqlClient = urqlData?.client;
      if (!urqlClient) return null;
      const result = await urqlClient.query(Query, { identity });
      return result.data?.shinkaiIdentities[0]?.rewardClaims ?? null;
    },
    staleTime: 60000,
  });
};
