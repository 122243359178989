import {
  Button,
  Stack,
  TextField,
  Typography,
  IconButton,
  Box,
  MenuItem,
  Divider,
} from "@mui/material";
import { useSimulateShinkaiRegistrySetDelegations } from "../generated";
import { ChangeEvent, useEffect, useState } from "react";
import { useAccount, useWriteContract } from "wagmi";
import { formatEther, parseEther } from "viem";
import DeleteIcon from "@mui/icons-material/Delete";
import KaiTokenAmount from "./KaiTokenAmount";
import { useDebounce } from "usehooks-ts";
import { compareArrays } from "../helper";
import clone from "just-clone";
import { useSnackbar } from "notistack";
import { ButtonMessage, SnackbarMessage } from "../utils/texts";
import { useQueryClient } from "@tanstack/react-query";
import { getIdentityData } from "../hooks/useGetIdentityData";
import QueryKey from "../utils/queryKeys";
import useWaitForTransactionReceipt from "../hooks/useWaitForTransactionReceipt";
import { useGetShinkaiNamespaces } from "../hooks/useGetShinkaiNamespaces";
import { useGetDefaultShinkaiNamespace } from "../hooks/useGetDefaultShinkaiNamespace";
import { useUrqlClient } from "../hooks/useUrqlClient";
import { getShinkaiRegistryAddress } from "../contracts";

type Props = {
  identity: string;
  stakedAmount: bigint;
  delegatedToThis: bigint;
  delegations:
    | {
        delegatee: { name: string; namespace: string };
        amount: bigint;
      }[]
    | undefined;
  isOwner: boolean;
  refetch: () => void;
};

export default function IdentityDelegationsForm({
  identity,
  stakedAmount,
  delegatedToThis,
  delegations,
  isOwner,
  refetch,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { data: urqlData } = useUrqlClient();
  const identityDelegations = delegations ?? [];
  const [editing, setEditing] = useState(false);
  const [delegationsArray, setDelegationsArray] = useState(identityDelegations);
  const [setDelegationsSettled, setSetDelegationsSettled] = useState(false);
  const [delegateesErrors, setDelegateesErrors] = useState<
    Record<number, string>
  >({});
  const debouncedDelegationsArray = useDebounce(delegationsArray, 500);
  const queryClient = useQueryClient();
  const { data: shinkaiNamespaces } = useGetShinkaiNamespaces();
  const { data: defaultShinkaiNamespace } = useGetDefaultShinkaiNamespace();

  const {
    data,
    isSuccess: isSuccessSetDelegations,
    isFetching,
  } = useSimulateShinkaiRegistrySetDelegations({
    address: getShinkaiRegistryAddress(),
    args: [
      identity,
      debouncedDelegationsArray.map((del) => {
        return {
          delegatee: `${del.delegatee.name}${del.delegatee.namespace}`,
          amount: del.amount,
        };
      }),
    ],
    query: { enabled: isOwner && validateForm(debouncedDelegationsArray) },
  });
  const { writeContract, data: hash, isPending } = useWriteContract();
  const { isLoading, isSuccess } = useWaitForTransactionReceipt({
    hash,
  });

  useEffect(() => {
    setSetDelegationsSettled(!!isSuccessSetDelegations);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessSetDelegations, isFetching]);

  useEffect(() => {
    if (isLoading) {
      enqueueSnackbar({
        message: SnackbarMessage.TransactionSubmitted,
        variant: "info",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar({
        message: SnackbarMessage.DelegationsEditSuccess,
        variant: "success",
      });
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    const validateExistenceOfDelegatees = async () => {
      const errors: typeof delegateesErrors = {};
      for (const [index, deleg] of debouncedDelegationsArray.entries()) {
        const identity = `${deleg.delegatee.name}${deleg.delegatee.namespace}`;
        if (identity === "") continue;
        const delegationIdentityData = await queryClient.fetchQuery({
          queryKey: [QueryKey.IDENTITY, { urqlData, identity }],
          queryFn: () =>
            getIdentityData({ client: urqlData?.client, identity }),
          staleTime: 60000,
        });
        if (!delegationIdentityData) {
          errors[index] = "Identity does not exist.";
        }
      }
      setDelegateesErrors(errors);
    };
    validateExistenceOfDelegatees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedDelegationsArray]);

  function handleEditingButtonClick() {
    if (editing) {
      if (delegationsArray !== identityDelegations) {
        writeContract(data!.request);
      }
    }
    setEditing(!editing);
  }

  function handleAddDelegationButtonClick() {
    const newArray = [...delegationsArray];
    newArray.push({
      delegatee: { name: "", namespace: defaultShinkaiNamespace },
      amount: 0n,
    });
    setDelegationsArray(newArray);
  }

  function handleCancelButtonClick() {
    setDelegationsArray(identityDelegations);
    setEditing(false);
  }

  function handleFormChange(
    index: number,
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    let data = clone(delegationsArray);
    if (event.target.name === "delegatee") {
      data[index].delegatee.name = event.target.value;
    } else if (event.target.name === "namespace") {
      data[index].delegatee.namespace = event.target.value;
    } else if (event.target.name === "amount") {
      data[index].amount = parseEther(event.target.value as any);
    }
    setSetDelegationsSettled(compareArrays(debouncedDelegationsArray, data));
    setDelegationsArray(data);
  }

  function removeFields(index: number) {
    let data = [...delegationsArray];
    data.splice(index, 1);
    setSetDelegationsSettled(compareArrays(debouncedDelegationsArray, data));
    setDelegationsArray(data);
  }

  function validateForm(array: typeof identityDelegations) {
    for (let i = 0; i < array.length; i++) {
      if (validateDelegatee(array, i).error || validateAmount(array, i).error) {
        return false;
      }
    }
    return true;
  }

  function validateDelegatee(array: typeof identityDelegations, index: number) {
    const delegatee = array[index].delegatee.name;
    if (!delegatee) {
      return {
        error: true,
        helperText: "Cannot be empty.",
      };
    }
    return { error: false, helperText: "" };
  }

  function validateAmount(array: typeof identityDelegations, index: number) {
    const amount = array[index].amount;
    if (amount === 0n) {
      return { error: true, helperText: "Amount must be greater than zero." };
    }
    if (
      array.map((del) => del.amount).reduce((prev, curr) => prev + curr, 0n) >
      stakedAmount
    ) {
      return {
        error: true,
        helperText:
          "Sum of delegations amount must not be greater than identity staked tokens amount.",
      };
    }
    return { error: false, helperText: "" };
  }

  const inputHasError =
    !validateForm(delegationsArray) || Object.keys(delegateesErrors).length > 0;
  const saveButtonDisabled =
    isLoading ||
    isPending ||
    (editing && inputHasError) ||
    !setDelegationsSettled;

  const delegatedByThis = identityDelegations.reduce(
    (prev, curr) => prev + curr.amount,
    0n,
  );

  return (
    <Stack gap={2}>
      <Stack gap={2} divider={<Divider />}>
        <Stack direction="row" gap={1}>
          <Typography variant="h6">KAI delegated to this identity:</Typography>
          <KaiTokenAmount amount={delegatedToThis} />
        </Stack>
        {!delegationsArray ? (
          <Typography>Could not retrieve delegations data.</Typography>
        ) : editing ? (
          <Stack gap={2}>
            <Stack direction="row" gap={1}>
              <Typography variant="h6">
                KAI available for delegation:
              </Typography>
              <KaiTokenAmount
                amount={
                  stakedAmount -
                  delegationsArray.reduce(
                    (prev, curr) => prev + curr.amount,
                    0n,
                  )
                }
              />
            </Stack>
            {delegationsArray?.map((deleg, index) => (
              <Stack direction={"row"} key={index} gap={2}>
                <Stack direction={"row"} sx={{ width: "70%" }}>
                  <TextField
                    name="delegatee"
                    color="secondary"
                    label="Delegatee"
                    size="small"
                    onChange={(e) => handleFormChange(index, e)}
                    value={deleg.delegatee.name}
                    error={
                      validateDelegatee(delegationsArray, index).error ||
                      !!delegateesErrors[index]
                    }
                    helperText={
                      validateDelegatee(delegationsArray, index).helperText ||
                      delegateesErrors[index]
                    }
                    sx={{ width: "70%" }}
                  />
                  <TextField
                    select
                    disabled
                    name="namespace"
                    value={deleg.delegatee.namespace}
                    label="Namespace"
                    size="small"
                    onChange={(e) => handleFormChange(index, e)}
                    sx={{ width: "30%", minWidth: "105px" }}
                  >
                    {Object.keys(shinkaiNamespaces).map((namespaceString) => (
                      <MenuItem key={namespaceString} value={namespaceString}>
                        {namespaceString}
                      </MenuItem>
                    ))}
                  </TextField>
                </Stack>
                <TextField
                  name="amount"
                  color="secondary"
                  label="Amount"
                  size="small"
                  onChange={(e) => handleFormChange(index, e)}
                  value={formatEther(deleg.amount)}
                  error={validateAmount(delegationsArray, index).error}
                  helperText={
                    validateAmount(delegationsArray, index).helperText
                  }
                  sx={{ width: "30%" }}
                />
                <IconButton
                  aria-label="delete"
                  sx={{ height: "40px" }}
                  onClick={() => removeFields(index)}
                >
                  <DeleteIcon />
                </IconButton>
              </Stack>
            ))}
          </Stack>
        ) : delegationsArray.length === 0 ? (
          <Typography variant="h6">
            Identity has not set any delegations
          </Typography>
        ) : (
          <>
            <Stack direction="row" gap={1}>
              <Typography variant="h6">
                KAI delegated by this identity:
              </Typography>
              <KaiTokenAmount amount={delegatedByThis} />
            </Stack>
            <Stack direction="row" gap={4}>
              <Stack gap={1}>
                <Typography sx={{ fontWeight: 600 }}>Delegatee</Typography>
                {delegationsArray?.map((deleg) => (
                  <Typography key={`${deleg.delegatee.name}-delegatee`}>
                    {deleg.delegatee.name}
                    {deleg.delegatee.namespace}
                  </Typography>
                ))}
              </Stack>
              <Stack gap={1}>
                <Typography sx={{ fontWeight: 600 }}>
                  Delegation amount
                </Typography>
                {delegationsArray?.map((deleg) => (
                  <Box
                    key={`${deleg.delegatee.name}-amount`}
                    sx={{ alignSelf: "end" }}
                  >
                    <KaiTokenAmount amount={deleg.amount} />
                  </Box>
                ))}
              </Stack>
            </Stack>
          </>
        )}
      </Stack>
      {isOwner && editing && (
        <Button variant="outlined" onClick={handleAddDelegationButtonClick}>
          Add new delegation
        </Button>
      )}
      {isOwner && (
        <Stack direction="row" gap={2}>
          <Button
            variant={saveButtonDisabled ? "outlined" : "contained"}
            onClick={handleEditingButtonClick}
            disabled={saveButtonDisabled}
          >
            {editing
              ? setDelegationsSettled || inputHasError
                ? "Save"
                : ButtonMessage.PreparingTx
              : isPending
              ? ButtonMessage.ConfirmTx
              : isLoading
              ? ButtonMessage.PendingTx
              : "Edit"}
          </Button>
          {editing && (
            <Button variant="outlined" onClick={handleCancelButtonClick}>
              Cancel
            </Button>
          )}
        </Stack>
      )}
    </Stack>
  );
}
