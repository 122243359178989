import { Alert, Container, Stack, Typography } from "@mui/material";
import ClaimIdentityForm from "../components/ClaimIdentityForm";
import IsConnectedWrapper from "../components/IsConnectedWrapper";
import { Link } from "react-router-dom";

export default function HomePage() {
  const queryParameters = new URLSearchParams(window.location.search);
  const encryptionPublicKeyFromUrl = queryParameters.get("encryption_pk") ?? "";
  const signaturePublicKeyFromUrl = queryParameters.get("signature_pk") ?? "";
  return (
    <>
      <Container>
        <IsConnectedWrapper>
          <Stack sx={{ alignItems: "center", my: 4 }}>
            <Typography
              variant="h2"
              component="h1"
              sx={{ mb: 4, textAlign: "center" }}
            >
              Register A New Identity
            </Typography>
            {(!encryptionPublicKeyFromUrl || !signaturePublicKeyFromUrl) && (
              <Alert severity="warning" color="error" sx={{ mb: 2 }}>
                If you are using Shinkai Desktop, make sure to{" "}
                <Link
                  to="https://docs.shinkai.com/connecting-to-the-shinkai-network/register-identity-onchain"
                  target="_blank"
                >
                  follow the docs
                </Link>{" "}
                before registering an identity below.
              </Alert>
            )}
            <ClaimIdentityForm />
          </Stack>
        </IsConnectedWrapper>
      </Container>
    </>
  );
}
