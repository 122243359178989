"use client";
import Button from "@mui/material/Button";
import { useAccount } from "wagmi";
import { useEffect, useState } from "react";
import { shinkaiRegistryAbi } from "../generated";
import {
  Keys,
  downloadKeys,
  generateDeviceEncryptionKeys,
  generateDeviceSignatureKeys,
} from "../keys";
import { simulateContract, writeContract } from "@wagmi/core";
import { useSnackbar } from "notistack";
import { ButtonMessage, SnackbarMessage } from "../utils/texts";
import { useModal } from "mui-modal-provider";
import ClaimIdentityDialog from "../dialogs/ClaimIdentityDialog";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import QueryKey from "../utils/queryKeys";
import { config } from "../wagmi";
import { useGetUserKaiTokenBalance } from "../hooks/useGetUserKaiTokenBalance";
import useWaitForTransactionReceipt from "../hooks/useWaitForTransactionReceipt";
import { env } from "../utils/envs";
import { getShinkaiRegistryAddress } from "../contracts";

type Props = {
  name: string;
  namespace: bigint;
  namespaceString: string;
  stakeAmount: bigint;
  owner: `0x${string}`;
  referrer: string;
  disabled?: boolean;
  loading: boolean;
  encryptionPublicKey: string;
  signaturePublicKey: string;
  nodeAddress: string;
};

export default function ClaimIdentityButton({
  name,
  namespace,
  namespaceString,
  stakeAmount,
  owner,
  referrer,
  disabled,
  loading,
  encryptionPublicKey,
  signaturePublicKey,
  nodeAddress,
}: Props) {
  const queryClient = useQueryClient();
  const { chain } = useAccount();
  const [hash, setHash] = useState<`0x${string}`>();
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { showModal } = useModal();
  const identity = `${name}${namespaceString}`;

  const {
    isLoading: isLoadingTxReceipt,
    isSuccess,
    error,
  } = useWaitForTransactionReceipt({
    hash,
  });

  const { queryKey: kaiBalanceQueryKey } = useGetUserKaiTokenBalance();

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar({
        message: SnackbarMessage.ClaimSuccess,
        variant: "success",
      });
      queryClient.invalidateQueries({ queryKey: [QueryKey.IDENTITIES] });
      queryClient.invalidateQueries({ queryKey: kaiBalanceQueryKey });
      setTimeout(() => {
        navigate(`/identity/${identity}`);
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar({
        message: `Error: ${error.message}`,
        variant: "error",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  async function prepareRequest(
    encryptionKeys: Keys,
    signatureKeys: Keys,
    nodeAddress: string,
  ) {
    const { request } = await simulateContract(config, {
      address: getShinkaiRegistryAddress(),
      abi: shinkaiRegistryAbi,
      functionName: "claimIdentityAndSetData",
      args: [
        {
          name,
          namespace,
          stakeAmount,
          owner,
          referrer,
        },
        {
          encryptionKey: encryptionKeys.publicKey,
          signatureKey: signatureKeys.publicKey,
          routing: false,
          addressOrProxyNodes: nodeAddress === "" ? [] : [nodeAddress],
        },
        [
          {
            delegatee:
              env.REACT_APP_DELEGATE_IDENTITY[
                chain?.id ?? env.REACT_APP_DEFAULT_CHAIN_ID
              ],
            amount:
              (stakeAmount * BigInt(env.REACT_APP_DELEGATE_PERCENTAGE)) / 100n,
          },
        ],
      ],
    });
    return request;
  }

  async function claimIdentity() {
    const encryptionKeys: Keys = encryptionPublicKey
      ? { publicKey: encryptionPublicKey, privateKey: "" }
      : await generateDeviceEncryptionKeys();
    const signatureKeys: Keys = signaturePublicKey
      ? { publicKey: signaturePublicKey, privateKey: "" }
      : await generateDeviceSignatureKeys();

    try {
      if (encryptionKeys.privateKey || signatureKeys.privateKey) {
        downloadKeys({
          encryptionKeys,
          signatureKeys,
          identity,
        });
        const txRequest = await prepareRequest(
          encryptionKeys,
          signatureKeys,
          nodeAddress,
        );
        const modal = showModal(ClaimIdentityDialog, {
          encryptionKeys,
          signatureKeys,
          identity,
          txRequest,
          onTxSubmit: (txHash) => {
            setHash(txHash);
          },
          onCancel: () => {
            modal.hide();
          },
        });
      } else {
        setIsLoading(true);
        const txRequest = await prepareRequest(
          encryptionKeys,
          signatureKeys,
          nodeAddress,
        );
        const txHash = await writeContract(config, txRequest);
        setHash(txHash);
        enqueueSnackbar({
          message: SnackbarMessage.TransactionSubmitted,
          variant: "info",
        });
      }
    } catch (err: any) {
      console.error(err);
    }
    setIsLoading(false);
  }

  return (
    <Button
      variant={
        disabled || isLoading || isLoadingTxReceipt ? "outlined" : "contained"
      }
      size="large"
      onClick={claimIdentity}
      disabled={disabled || isLoading || isLoadingTxReceipt}
      sx={{ justifySelf: "center" }}
    >
      {loading
        ? ButtonMessage.PreparingTx
        : isLoading
        ? ButtonMessage.ConfirmTx
        : isLoadingTxReceipt
        ? ButtonMessage.PendingTx
        : encryptionPublicKey && signaturePublicKey
        ? `Register identity`
        : `Generate keys and register identity`}
    </Button>
  );
}
