import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

type Props = {
  identityName: string;
};

export default function IdentityButton({ identityName }: Props) {
  return (
    <Link to={`/identity/${identityName}`}>
      <Button variant="contained" size="large" sx={{ textTransform: "none" }}>
        @@{identityName}
      </Button>
    </Link>
  );
}
