"use client";
import { useAccount } from "wagmi";
import { useReadShinkaiTokenAllowance } from "../generated";
import { getShinkaiTokenAddress } from "../contracts";

export const useGetUserKaiTokenAllowance = (spender: `0x${string}`) => {
  const { address } = useAccount();
  return useReadShinkaiTokenAllowance({
    address: getShinkaiTokenAddress(),
    args: [address!, spender],
    query: {
      enabled: address !== undefined,
      staleTime: 60000,
    },
  });
};
