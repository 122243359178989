import { Container, Divider, Stack, Typography } from "@mui/material";
import { useAccount } from "wagmi";
import IdentityList from "../components/IdentityList";
import IsConnectedWrapper from "../components/IsConnectedWrapper";
import ProfileSummary from "../components/ProfileSummary";

export default function ProfilePage() {
  const { address } = useAccount();

  return (
    <>
      <Container>
        <IsConnectedWrapper>
          <Stack
            sx={{ alignItems: "center", textAlign: "center", my: 4, gap: 4 }}
          >
            <Typography variant="h2" component="h1">
              Your Profile
            </Typography>
            <ProfileSummary />
            <Divider flexItem />
            <Typography variant="h4">Your Registered Identities</Typography>
            <IdentityList owner={address?.toLowerCase()} />
          </Stack>
        </IsConnectedWrapper>
      </Container>
    </>
  );
}
