export const SnackbarMessage = {
  TransactionSubmitted: "Transaction submitted, awaiting confirmation...",
  ClaimSuccess: "Identity registration successful!",
  UnclaimSuccess: "Identity deletion successful!",
  InfoEditSuccess: "Identity info edit successful!",
  StakeChangeSuccess: "Identity stake change successful!",
  RewardsClaimSuccess: "Claiming rewards successful!",
  DelegationsEditSuccess: "Identity delegations edit successful!",
  ApproveSuccess: "Approval successful, you can proceed!",
  KeysMismatch:
    "The encryption and signature public keys do not match. Please update your keys.",
  KeysMatch: "Your encryption and signature public keys are correctly in sync.",
  ProxyNodeInvalid:
    "Not strictly a problem, but your node is not using a proxy. Ensure your PORT is open or simply use ‘relayer_pub_01’ as your proxy.",
  NodeAddressIsLocalhost:
    "Your identity is not reachable from other nodes in the Shinkai Network. Update your address or use a proxy like ‘relayer_pub_01’.",
};

export const ButtonMessage = {
  PreparingTx: "Preparing transaction...",
  ConfirmTx: "Confirm transaction",
  PendingTx: "Transaction pending...",
};
