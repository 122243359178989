import { Stack, Divider } from "@mui/material";
import { ZERO_ADDRESS } from "../helper";
import IdentityClaimHistory from "./IdentityClaimHistory";
import IdentityRewardsForm from "./IdentityRewardsForm";
import { Identity } from "../types";
import { useAccount } from "wagmi";

type Props = {
  identityData: Identity;
};

export default function IdentityRewardsTabContent({ identityData }: Props) {
  const { address } = useAccount();
  const isOwner =
    identityData?.nft.owner.toLowerCase() === address?.toLowerCase();

  return (
    <Stack width="100%" divider={<Divider />} gap={2}>
      <IdentityRewardsForm
        identity={identityData.identityRaw}
        isOwner={isOwner}
        isClaimed={identityData.nft.owner !== ZERO_ADDRESS}
        stakedTokens={identityData.stakedTokens}
        delegatedTokens={identityData.delegatedTokens}
      />
      <IdentityClaimHistory identity={identityData.identityRaw} />
    </Stack>
  );
}
