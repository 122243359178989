"use client";
import { Stack } from "@mui/material";
import { TOKENS, addTokenToWallet, canRegisterToken } from "../tokens";
import KaiTokenAmount from "./KaiTokenAmount";
import { useGetUserKaiTokenBalance } from "../hooks/useGetUserKaiTokenBalance";

export default function KaiTokenBalance() {
  const { data: shinTokenBalance } = useGetUserKaiTokenBalance();

  return shinTokenBalance != null ? (
    <Stack
      direction="row"
      onClick={() => {
        if (canRegisterToken()) {
          addTokenToWallet(TOKENS.KAI);
        }
      }}
      sx={{
        position: "relative",
        gap: 1,
        p: 1,
        cursor: canRegisterToken() ? "pointer" : "default",
        background: "var(--rk-colors-connectButtonBackground)",
        borderRadius: "var(--rk-radii-connectButton);",
        boxShadow: "var(--rk-shadows-connectButton)",
        color: "var(--rk-colors-connectButtonText)",
        transition: "0.125s ease",
        ":hover": {
          transform: "scale(1.025)",
        },
        ":active": {
          transform: "scale(0.95)",
        },
      }}
    >
      <KaiTokenAmount amount={shinTokenBalance} />
    </Stack>
  ) : (
    <></>
  );
}
