import {
  AppBar,
  Breakpoint,
  Button,
  Container,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Toolbar,
  useTheme,
} from "@mui/material";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import KaiTokenBalance from "./KaiTokenBalance";
import { Link } from "react-router-dom";
import { useAccount } from "wagmi";
import { useState } from "react";
import { Close as CloseIcon, Menu as MenuIcon } from "@mui/icons-material";

function PagesLinks({ onClick }: { onClick?: () => void }) {
  const { isConnected } = useAccount();
  return (
    <>
      <Link to="/" onClick={onClick}>
        <Button variant="text">Home</Button>
      </Link>
      {isConnected ? (
        <Link to="/profile" onClick={onClick}>
          <Button variant="text">Your Profile</Button>
        </Link>
      ) : null}
      <Link to="/identity" onClick={onClick}>
        <Button variant="text">Protocol Identities</Button>
      </Link>
      <a
        href="https://sepolia-faucet.shinkai.com"
        target="_blank"
        rel="noreferrer"
      >
        <Button variant="text">Faucet</Button>
      </a>
    </>
  );
}

export default function Navbar() {
  const [drawerOpened, setDrawerOpened] = useState(false);
  const theme = useTheme();
  const breakpoint: Breakpoint = "md";

  return (
    <>
      <AppBar sx={{ bgcolor: theme.palette.background.default }}>
        <Container>
          <Toolbar sx={{ gap: 2, justifyContent: "space-between" }}>
            <Link to="/" style={{ display: "flex" }}>
              <img src="/logo.png" alt="Shinkai logo" height={32} />
            </Link>
            <Stack
              sx={{
                flexGrow: 1,
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Stack
                sx={{
                  display: { xs: "none", [breakpoint]: "flex" },
                  flexDirection: "row",
                }}
                divider={<Divider orientation="vertical" flexItem />}
              >
                <PagesLinks />
              </Stack>
            </Stack>
            <Stack
              sx={{
                display: { xs: "none", [breakpoint]: "flex" },
              }}
            >
              <KaiTokenBalance />
            </Stack>
            <ConnectButton
              accountStatus="address"
              showBalance={false}
              chainStatus="icon"
            />
            <Stack
              direction="row"
              alignItems="center"
              gap={2}
              sx={{
                justifyContent: "end",
                display: { xs: "flex", [breakpoint]: "none" },
              }}
            >
              {/* <Typography variant="button">{translate(currentPage)}</Typography> */}

              <IconButton
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => setDrawerOpened(true)}
              >
                <MenuIcon />
              </IconButton>
            </Stack>
          </Toolbar>
        </Container>
        {/* Mobile menu */}
        <Drawer
          id="menu-appbar"
          anchor="right"
          open={drawerOpened}
          onClose={() => setDrawerOpened(false)}
          sx={{
            "& .MuiPaper-root": {
              width: "100vw",
              bgcolor: theme.palette.background.default,
            },
          }}
        >
          <Container sx={{ height: "100%" }}>
            <Stack height="100%" py={2}>
              <Stack sx={{ alignSelf: "end" }}>
                <IconButton
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={() => setDrawerOpened(false)}
                >
                  <CloseIcon />
                </IconButton>
              </Stack>
              <Stack
                flexGrow={1}
                alignItems="center"
                justifyContent="center"
                gap={5}
              >
                <img src="/logo.png" alt="Shinkai logo" height={32} />
                <Stack sx={{ gap: 2, alignItems: "center" }}>
                  <PagesLinks onClick={() => setDrawerOpened(false)} />
                </Stack>
              </Stack>
            </Stack>
          </Container>
        </Drawer>
      </AppBar>
      <Toolbar />
    </>
  );
}
