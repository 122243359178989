"use client";
import { Identity } from "../types";
import { useQuery } from "@tanstack/react-query";
import QueryKey from "../utils/queryKeys";
import { Client, gql } from "@urql/core";
import { useUrqlClient } from "./useUrqlClient";

type QueryResponse = {
  shinkaiIdentities: Identity[];
};

const Query = gql<QueryResponse, { identity: string }>`
  query ($identity: ID!) {
    shinkaiIdentities(where: { identityRaw: $identity }) {
      identity: id
      identityRaw
      encryptionKey
      signatureKey
      addressOrProxyNodes
      routing
      delegations {
        delegatee
        amount
      }
      stakedTokens
      delegatedTokens
      nft {
        tokenId
        owner
      }
      createdAt
      updatedAt
    }
  }
`;

export const getIdentityData = async ({
  client,
  identity,
}: {
  client?: Client;
  identity: string;
}) => {
  if (!client) return null;
  const result = await client.query(Query, { identity });
  return result.data?.shinkaiIdentities[0] ?? null;
};

export const useGetIdentityData = (identity: string) => {
  const { data: urqlData } = useUrqlClient();

  return useQuery({
    queryKey: [QueryKey.IDENTITIES, { urqlData, identity }],
    queryFn: async () => {
      const urqlClient = urqlData?.client;
      if (!urqlClient) return null;
      return getIdentityData({ client: urqlClient, identity });
    },
    enabled: !!identity,
    staleTime: 60000,
  });
};
