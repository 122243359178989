import {
  Checkbox,
  CircularProgress,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Pagination,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Fragment, useMemo, useState } from "react";
import KaiTokenAmount from "./KaiTokenAmount";
import { useGetIdentityClaimHistory } from "../hooks/useGetIdentityClaimHistory";

type Props = {
  identity: string;
};

const filterOptions = ["Staking", "Delegation"];
const pageSize = 10;

export default function IdentityClaimHistory({ identity }: Props) {
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState<string[]>([]);
  const {
    data: claimHistoryData,
    isLoading,
    error,
  } = useGetIdentityClaimHistory(identity);

  const claimHistoryDataFiltered = useMemo(
    () =>
      claimHistoryData?.filter(
        (claimHistoryDat) =>
          filters.length === 0 || filters.includes(claimHistoryDat.type),
      ),
    [claimHistoryData, filters],
  );

  const claimHistoryDataFilteredPaged = useMemo(
    () =>
      claimHistoryDataFiltered?.slice((page - 1) * pageSize, page * pageSize),
    [claimHistoryDataFiltered, page],
  );

  const handleChangeFilters = (event: SelectChangeEvent<typeof filters>) => {
    const {
      target: { value },
    } = event;
    setFilters(typeof value === "string" ? value.split(",") : value);
    setPage(1);
  };
  return (
    <Stack alignItems={"center"} gap={2}>
      <Stack direction="row" alignItems={"center"} width="100%">
        <Typography variant="h5" flexGrow={1}>
          Rewards claim history
        </Typography>
        <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id="filters-multiple-checkbox-label">Filter</InputLabel>
          <Select
            labelId="filters-multiple-checkbox-label"
            id="filters-multiple-checkbox"
            multiple
            value={filters}
            onChange={handleChangeFilters}
            input={<OutlinedInput label="Filter" />}
            renderValue={(selected) => selected.join(", ")}
          >
            {filterOptions.map((filterOption) => (
              <MenuItem key={filterOption} value={filterOption}>
                <Checkbox checked={filters.indexOf(filterOption) > -1} />
                <ListItemText primary={filterOption} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
      <Stack gap={4} width="100%">
        <Grid container spacing={2}>
          <Grid xs={4}>
            <Typography>Timestamp</Typography>
          </Grid>
          <Grid xs={4}>
            <Typography>Type</Typography>
          </Grid>
          <Grid xs={4} sx={{ display: "flex", justifyContent: "end" }}>
            <Typography>Amount</Typography>
          </Grid>
          {isLoading && (
            <Grid xs={12} sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Grid>
          )}
          {claimHistoryDataFilteredPaged &&
            (error ? (
              <Grid xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                <Typography>
                  Error occurred when fetching identity rewards claim history.
                </Typography>
              </Grid>
            ) : claimHistoryDataFilteredPaged.length === 0 ? (
              <Grid xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                <Typography>No results.</Typography>
              </Grid>
            ) : (
              claimHistoryDataFilteredPaged.map((claimHistoryDat) => (
                <Fragment
                  key={`${claimHistoryDat.timestamp}-${claimHistoryDat.type}`}
                >
                  <Grid xs={4}>
                    <Typography>
                      {new Date(
                        Number(claimHistoryDat.timestamp) * 1000,
                      ).toLocaleString()}
                    </Typography>
                  </Grid>
                  <Grid xs={4}>
                    <Typography>{claimHistoryDat.type}</Typography>
                  </Grid>
                  <Grid xs={4} sx={{ display: "flex", justifyContent: "end" }}>
                    <KaiTokenAmount amount={BigInt(claimHistoryDat.amount)} />
                  </Grid>
                </Fragment>
              ))
            ))}
        </Grid>
        {claimHistoryDataFiltered &&
          claimHistoryDataFiltered.length > pageSize && (
            <Pagination
              count={Math.ceil(claimHistoryDataFiltered.length / pageSize)}
              page={page}
              onChange={(_event, value) => {
                setPage(value);
              }}
              sx={{ alignSelf: "center" }}
            />
          )}
      </Stack>
    </Stack>
  );
}
