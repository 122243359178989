import { useQuery } from "@tanstack/react-query";
import { useAccount } from "wagmi";
import QueryKey from "../utils/queryKeys";
import { createClient, fetchExchange } from "@urql/core";
import { getBaseApiURL } from "../helper";

export const useUrqlClient = () => {
  const { chainId } = useAccount();
  return useQuery({
    queryKey: [QueryKey.URQL_CLIENT, { chainId }],
    queryFn: () => ({
      client: createClient({
        url: getBaseApiURL(chainId),
        exchanges: [fetchExchange],
      }),
      chainId,
    }),
    staleTime: Infinity,
  });
};
