import * as ed from "@noble/ed25519";
import { generateKeyPair } from "curve25519-js";

export type Keys = {
  privateKey: string;
  publicKey: string;
};

type KeyFileObject = {
  identity: string;
  encryptionPublicKey: string;
  encryptionPrivateKey: string;
  signaturePublicKey: string;
  signaturePrivateKey: string;
};

export function toHexString(byteArray: Uint8Array) {
  return Array.from(byteArray, function (byte) {
    return ("0" + (byte & 0xff).toString(16)).slice(-2);
  }).join("");
}

const generateEncryptionKeys = async (seed: Uint8Array): Promise<Keys> => {
  const encryptionKeys = generateKeyPair(seed);
  const privateKey: string = toHexString(encryptionKeys.private);
  const publicKey: string = toHexString(encryptionKeys.public);

  return {
    privateKey,
    publicKey,
  };
};

const generateSignatureKeys = async (): Promise<Keys> => {
  const privKey = ed.utils.randomPrivateKey();
  const pubKey = await ed.getPublicKeyAsync(privKey);

  const privateKey: string = toHexString(privKey);
  const publicKey: string = toHexString(pubKey);

  return {
    privateKey,
    publicKey,
  };
};

export const generateDeviceEncryptionKeys = async (): Promise<Keys> => {
  const seed = crypto.getRandomValues(new Uint8Array(32));
  const { privateKey, publicKey } = await generateEncryptionKeys(seed);
  return {
    privateKey,
    publicKey,
  };
};

export const generateDeviceSignatureKeys = async (): Promise<Keys> => {
  const { privateKey, publicKey } = await generateSignatureKeys();
  return {
    privateKey,
    publicKey,
  };
};

export const downloadKeys = async ({
  encryptionKeys,
  signatureKeys,
  identity,
}: {
  encryptionKeys: Keys;
  signatureKeys: Keys;
  identity: string;
}) => {
  const keyFileObject: KeyFileObject = {
    identity: `@@${identity}`,
    encryptionPrivateKey:
      encryptionKeys.privateKey || "Unknown, since you supplied the public key",
    encryptionPublicKey: encryptionKeys.publicKey,
    signaturePrivateKey:
      signatureKeys.privateKey || "Unknown, since you supplied the public key",
    signaturePublicKey: signatureKeys.publicKey,
  };
  var element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/json;charset=utf-8," +
      encodeURIComponent(JSON.stringify(keyFileObject)),
  );
  element.setAttribute("download", `${identity}.json`);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};
