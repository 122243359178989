import { RainbowKitProvider, lightTheme } from "@rainbow-me/rainbowkit";
import * as React from "react";
import "@rainbow-me/rainbowkit/styles.css";
import { WagmiProvider } from "wagmi";

import { config } from "./wagmi";
import { SnackbarProvider } from "notistack";
import { ModalProvider } from "mui-modal-provider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

export function Providers({ children }: { children: React.ReactNode }) {
  const [mounted, setMounted] = React.useState(false);
  React.useEffect(() => setMounted(true), []);
  return (
    <SnackbarProvider
      anchorOrigin={{ horizontal: "right", vertical: "top" }}
      classes={{ containerRoot: "snackbarContainer" }}
    >
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <RainbowKitProvider
            theme={lightTheme({
              accentColor: "#EC6B67",
              accentColorForeground: "#FFFFFF",
            })}
          >
            <ModalProvider>{mounted && children}</ModalProvider>
          </RainbowKitProvider>
        </QueryClientProvider>
      </WagmiProvider>
    </SnackbarProvider>
  );
}
