"use client";
import { useAccount } from "wagmi";
import QueryKey from "../utils/queryKeys";
import { useQuery } from "@tanstack/react-query";
import { DEFAULT_SHINKAI_NAMESPACE } from "../utils/constants";
import { env } from "../utils/envs";

export const useGetDefaultShinkaiNamespace = () => {
  const { chainId } = useAccount();

  return useQuery({
    queryKey: [QueryKey.DEFAULT_SHINKAI_NAMESPACE, { chainId }],
    queryFn: () =>
      DEFAULT_SHINKAI_NAMESPACE[chainId ?? env.REACT_APP_DEFAULT_CHAIN_ID],
    initialData: "",
  });
};
