import { arbitrum, arbitrumSepolia } from "wagmi/chains";

import { config } from "./wagmi";
import { getChainId } from "@wagmi/core";

export const shinkaiTokenAddressMap = {
  [arbitrum.id]: "0xFceC6EEBa0dCc7b94e47d309fE77173a9fA4208e",
  [arbitrumSepolia.id]: "0xFceC6EEBa0dCc7b94e47d309fE77173a9fA4208e",
} as const;

export const shinkaiNftAddressMap = {
  [arbitrum.id]: "0x30BCD179DF1145953f7775b9433f9FB446112b86",
  [arbitrumSepolia.id]: "0x30BCD179DF1145953f7775b9433f9FB446112b86",
} as const;

export const shinkaiRegistryAddressMap = {
  [arbitrum.id]: "0x1d2D57F78Bc3B878aF68c411a03AcF327c85e0D6",
  [arbitrumSepolia.id]: "0x1d2D57F78Bc3B878aF68c411a03AcF327c85e0D6",
} as const;

export const getShinkaiTokenAddress = () => {
  const chainId = getChainId(config);
  return shinkaiTokenAddressMap[chainId];
};

export const getShinkaiNftAddress = () => {
  const chainId = getChainId(config);
  return shinkaiNftAddressMap[chainId];
};

export const getShinkaiRegistryAddress = () => {
  const chainId = getChainId(config);
  return shinkaiRegistryAddressMap[chainId];
};
