import { createTheme, responsiveFontSizes } from "@mui/material/styles";

export let theme = createTheme({
  palette: {
    primary: { main: "#EC6B67" },
    secondary: { main: "#1C3A3A" },
    background: { default: "#f7edd9" },
  },
  typography: {
    fontFamily: '"Inter", sans-serif',
    /*...font.style,*/ h1: { fontWeight: 800 },
    h2: { fontWeight: 800 },
    button: { fontWeight: 600 },
  },
});
theme = {
  ...theme,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 100,
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          [theme.breakpoints.up("xs")]: {
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      },
    },
  },
};
theme = responsiveFontSizes(theme);
