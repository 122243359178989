"use client";
import {
  Stack,
  TextField,
  Button,
  Typography,
  Switch,
  FormControlLabel,
  IconButton,
  MenuItem,
  Tooltip,
  Box,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { useSimulateShinkaiRegistrySetData } from "../generated";
import { useWriteContract } from "wagmi";
import DeleteIcon from "@mui/icons-material/Delete";
import { compareArrays } from "../helper";
import { Identity } from "../types";
import { useDebounce } from "usehooks-ts";
import { useSnackbar } from "notistack";
import { ButtonMessage, SnackbarMessage } from "../utils/texts";
import { useQueryClient } from "@tanstack/react-query";
import QueryKey from "../utils/queryKeys";
import { getIdentityData } from "../hooks/useGetIdentityData";
import clone from "just-clone";
import useWaitForTransactionReceipt from "../hooks/useWaitForTransactionReceipt";
import { useGetShinkaiNamespaces } from "../hooks/useGetShinkaiNamespaces";
import { useGetDefaultShinkaiNamespace } from "../hooks/useGetDefaultShinkaiNamespace";
import { useUrqlClient } from "../hooks/useUrqlClient";
import { getShinkaiRegistryAddress } from "../contracts";

type Props = {
  identityData: Identity;
  isOwner: boolean;
  refetchIdentityData: () => void;
};

const DEFAULT_TCP_RELAYER = "relayer_pub_01";
const LOCALHOST_SHINKAI_NODE_ADDRESS = "http://127.0.0.1";

export function IdentityDataInfoForm({
  identityData,
  isOwner,
  refetchIdentityData,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { data: urqlData } = useUrqlClient();
  const identityAddressOrProxyNodes = identityData?.addressOrProxyNodes ?? [];

  const identityRouting = identityData?.routing ?? false;
  const identityNodeAddress = identityRouting
    ? ""
    : identityAddressOrProxyNodes[0] ?? "";
  const identityProxyNodes = identityRouting
    ? identityAddressOrProxyNodes.map((node) => {
        return {
          name: node.substring(0, node.lastIndexOf(".")),
          namespace: node.substring(node.lastIndexOf(".")),
        };
      })
    : [];
  const identityEncryptionKey = identityData?.encryptionKey ?? "";
  const identitySignatureKey = identityData?.signatureKey ?? "";

  const [editing, setEditing] = useState(false);
  const [routing, setRouting] = useState(identityRouting);
  const [nodeAddress, setNodeAddress] = useState(identityNodeAddress);
  const [proxyNodes, setProxyNodes] = useState(identityProxyNodes);
  const [encryptionKey, setEncryptionKey] = useState(identityEncryptionKey);
  const [signatureKey, setSignatureKey] = useState(identitySignatureKey);
  const [setDataSettled, setSetDataSettled] = useState(false);
  const [proxyNodesErrors, setProxyNodesErrors] = useState<
    Record<string, string>
  >({});

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const encryptionPublicKeyFromUrl =
      queryParameters.get("encryption_pk") ?? "";
    const signaturePublicKeyFromUrl = queryParameters.get("signature_pk") ?? "";
    if (!encryptionPublicKeyFromUrl && !signaturePublicKeyFromUrl) return;
    const hasDefaultTcpRelayer = proxyNodes.find(
      (node) => node.name === DEFAULT_TCP_RELAYER,
    );
    if (!hasDefaultTcpRelayer) {
      enqueueSnackbar({
        message: SnackbarMessage.ProxyNodeInvalid,
        variant: "warning",
      });
    }
    if (nodeAddress.includes(LOCALHOST_SHINKAI_NODE_ADDRESS)) {
      enqueueSnackbar({
        message: SnackbarMessage.NodeAddressIsLocalhost,
        variant: "error",
      });
    }
    if (
      encryptionPublicKeyFromUrl !== identityEncryptionKey ||
      signaturePublicKeyFromUrl !== identitySignatureKey
    ) {
      enqueueSnackbar({
        message: SnackbarMessage.KeysMismatch,
        variant: "error",
      });
    } else {
      enqueueSnackbar({
        message: SnackbarMessage.KeysMatch,
        variant: "success",
      });
    }
  }, [identityEncryptionKey, nodeAddress, identitySignatureKey]);

  const debouncedNodeAddress = useDebounce(nodeAddress, 500);
  const debouncedProxyNodes = useDebounce(proxyNodes, 500);
  const debouncedEncryptionKey = useDebounce(encryptionKey, 500);
  const debouncedSignatureKey = useDebounce(signatureKey, 500);

  const queryClient = useQueryClient();
  const { data: shinkaiNamespaces } = useGetShinkaiNamespaces();
  const { data: defaultShinkaiNamespace } = useGetDefaultShinkaiNamespace();

  const {
    data,
    error: errorSetData,
    isSuccess: isSuccessSetData,
    isFetching,
  } = useSimulateShinkaiRegistrySetData({
    address: getShinkaiRegistryAddress(),
    args: [
      identityData.identityRaw,
      {
        encryptionKey: debouncedEncryptionKey,
        signatureKey: debouncedSignatureKey,
        routing,
        addressOrProxyNodes: routing
          ? debouncedProxyNodes.map((node) => `${node.name}${node.namespace}`)
          : [debouncedNodeAddress],
      },
    ],
    query: { enabled: isOwner },
  });

  const { writeContract, data: hash, isPending } = useWriteContract();
  const {
    isLoading,
    isSuccess: isSuccessTxReceipt,
    error: errorTxReceipt,
  } = useWaitForTransactionReceipt({
    hash,
  });

  useEffect(() => {
    setSetDataSettled(!!isSuccessSetData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessSetData, isFetching]);

  useEffect(() => {
    if (isLoading) {
      enqueueSnackbar({
        message: SnackbarMessage.TransactionSubmitted,
        variant: "info",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (isSuccessTxReceipt) {
      enqueueSnackbar({
        message: SnackbarMessage.InfoEditSuccess,
        variant: "success",
      });
      refetchIdentityData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessTxReceipt]);

  useEffect(() => {
    if (errorSetData || errorTxReceipt) {
      enqueueSnackbar({
        message: `Error: ${(errorSetData || errorTxReceipt)!.message}`,
        variant: "error",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorSetData, errorTxReceipt]);

  useEffect(() => {
    const validateExistenceOfDelegatees = async () => {
      const errors: typeof proxyNodesErrors = {};
      for (const node of debouncedProxyNodes) {
        if (node.name === "") continue;
        const identity = `${node.name}${node.namespace}`;
        const delegationIdentityData = await queryClient.fetchQuery({
          queryKey: [QueryKey.IDENTITY, { urqlData, identity }],
          queryFn: () =>
            getIdentityData({ client: urqlData?.client, identity }),
          staleTime: 60000,
        });
        if (!delegationIdentityData) {
          errors[identity] = "Identity does not exist.";
        }
      }
      setProxyNodesErrors(errors);
    };
    validateExistenceOfDelegatees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedProxyNodes]);

  function handleEditingButtonClick() {
    if (editing) {
      if (
        identityNodeAddress !== nodeAddress ||
        !compareArrays(identityProxyNodes, proxyNodes) ||
        identityEncryptionKey !== encryptionKey ||
        identitySignatureKey !== signatureKey
      )
        writeContract(data!.request);
    }
    setEditing(!editing);
  }

  function handleCancelButtonClick() {
    setNodeAddress(identityNodeAddress);
    setProxyNodes(identityProxyNodes);
    setRouting(identityRouting);
    setEncryptionKey(identityEncryptionKey);
    setSignatureKey(identitySignatureKey);
    setEditing(false);
  }

  function handleAddProxyNodeButtonClick() {
    const newArray = [...proxyNodes];
    newArray.push({ name: "", namespace: defaultShinkaiNamespace });
    setProxyNodes(newArray);
  }

  function handleFormChange(
    index: number,
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    let data = clone(proxyNodes);
    if (event.target.name === "proxyName") {
      data[index].name = event.target.value;
    } else if (event.target.name === "proxyNamespace") {
      data[index].namespace = event.target.value;
    }

    setSetDataSettled(compareArrays(debouncedProxyNodes, data));
    setProxyNodes(data);
  }

  function removeFields(index: number) {
    let data = [...proxyNodes];
    data.splice(index, 1);
    setSetDataSettled(compareArrays(debouncedProxyNodes, data));
    setProxyNodes(data);
  }

  function handleNodeAddressChanged(newValue: string) {
    setSetDataSettled(newValue === debouncedNodeAddress);
    setNodeAddress(newValue);
  }

  function handleEncryptionKeyChanged(newValue: string) {
    setSetDataSettled(newValue === debouncedEncryptionKey);
    setEncryptionKey(newValue);
  }

  function handleSignatureKeyChanged(newValue: string) {
    setSetDataSettled(newValue === debouncedSignatureKey);
    setSignatureKey(newValue);
  }

  function validateNode(index: number) {
    const node = proxyNodes[index];
    if (!node.name) {
      return {
        error: true,
        helperText: "Cannot be empty.",
      };
    }
    return { error: false, helperText: "" };
  }

  const inputHasError = Object.keys(proxyNodesErrors).length > 0;
  const saveButtonDisabled =
    isLoading || isPending || !setDataSettled || inputHasError;

  return (
    <Stack sx={{ gap: 2, alignSelf: "start", width: "100%" }}>
      <Stack
        sx={{
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "stretch", sm: routing ? "start" : "center" },
          gap: 2,
          minHeight: 40,
        }}
      >
        <Typography variant="h6">Node address / proxy nodes:</Typography>
        {editing ? (
          <Stack
            sx={{
              flexDirection: { xs: "column", sm: "row" },
              gap: 2,
              flexGrow: 1,
              alignItems: { xs: "stretch", sm: "start" },
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={routing}
                  onChange={() => setRouting(!routing)}
                />
              }
              label="Use proxy"
              sx={{ display: { xs: "inline-flex", sm: "none" } }}
            />
            {routing ? (
              <Stack sx={{ gap: 1, flexGrow: 1 }}>
                {proxyNodes?.map((node, index) => {
                  const identity = `${node.name}${node.namespace}`;
                  return (
                    <Stack direction={"row"} key={index} gap={2}>
                      <TextField
                        color="secondary"
                        name="proxyName"
                        label="Proxy node - identity name"
                        size="small"
                        onChange={(e) => handleFormChange(index, e)}
                        value={node.name}
                        fullWidth
                        error={
                          validateNode(index).error ||
                          !!proxyNodesErrors[identity]
                        }
                        helperText={
                          validateNode(index).helperText ||
                          proxyNodesErrors[identity]
                        }
                      />
                      <TextField
                        select
                        disabled
                        name="proxyNamespace"
                        value={node.namespace}
                        label="Namespace"
                        size="small"
                        onChange={(e) => handleFormChange(index, e)}
                        sx={{ width: "30%", minWidth: "105px" }}
                      >
                        {Object.keys(shinkaiNamespaces).map(
                          (namespaceString) => (
                            <MenuItem
                              key={namespaceString}
                              value={namespaceString}
                            >
                              {namespaceString}
                            </MenuItem>
                          ),
                        )}
                      </TextField>
                      <IconButton
                        aria-label="delete"
                        sx={{ height: "40px" }}
                        onClick={() => removeFields(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Stack>
                  );
                })}
                <Button
                  variant="outlined"
                  onClick={handleAddProxyNodeButtonClick}
                >
                  Add new proxy node
                </Button>
              </Stack>
            ) : (
              <TextField
                color="secondary"
                label="Node address"
                size="small"
                onChange={(e) => handleNodeAddressChanged(e.target.value)}
                value={nodeAddress}
                sx={{ flexGrow: 1 }}
              />
            )}
            <FormControlLabel
              control={
                <Switch
                  checked={routing}
                  onChange={() => setRouting(!routing)}
                />
              }
              label="Use proxy"
              sx={{ display: { xs: "none", sm: "inline-flex" } }}
            />
          </Stack>
        ) : (
          <Box sx={{ display: "flex", alignItems: "start", gap: 1 }}>
            <Stack sx={{ gap: 1, justifyContent: "center" }}>
              {(identityRouting ? proxyNodes : [nodeAddress]).map(
                (addressOrNode) => {
                  const identity =
                    typeof addressOrNode === "string"
                      ? addressOrNode
                      : `${addressOrNode.name}${addressOrNode.namespace}`;
                  return <Typography key={identity}>{identity}</Typography>;
                },
              )}
            </Stack>
            {identityRouting && (
              <Tooltip title="Use Proxy Node">
                <Box
                  sx={{
                    width: "30px",
                    height: "30px",
                    padding: "7px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                    backgroundColor: "#dbd5c7",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <circle cx="6" cy="19" r="3" />
                    <path d="M9 19h8.5a3.5 3.5 0 0 0 0-7h-11a3.5 3.5 0 0 1 0-7H15" />
                    <circle cx="18" cy="5" r="3" />
                  </svg>
                </Box>
              </Tooltip>
            )}
          </Box>
        )}
      </Stack>
      <Stack direction={"row"} alignItems={"center"} gap={2} minHeight={40}>
        <Typography variant="h6">Encryption Public Key:</Typography>
        {editing ? (
          <TextField
            color="secondary"
            label="Encryption Public Key"
            size="small"
            onChange={(e) => handleEncryptionKeyChanged(e.target.value)}
            value={encryptionKey}
            sx={{ flexGrow: 1 }}
          />
        ) : (
          <Typography sx={{ overflowWrap: "anywhere" }}>
            {encryptionKey}
          </Typography>
        )}
      </Stack>
      <Stack direction={"row"} alignItems={"center"} gap={2} minHeight={40}>
        <Typography variant="h6">Signature Public Key:</Typography>
        {editing ? (
          <TextField
            color="secondary"
            label="Signature Public Key"
            size="small"
            onChange={(e) => handleSignatureKeyChanged(e.target.value)}
            value={signatureKey}
            sx={{ flexGrow: 1 }}
          />
        ) : (
          <Typography sx={{ overflowWrap: "anywhere" }}>
            {signatureKey}
          </Typography>
        )}
      </Stack>
      {isOwner && (
        <Stack direction="row" gap={2}>
          <Button
            variant={saveButtonDisabled ? "outlined" : "contained"}
            onClick={handleEditingButtonClick}
            disabled={saveButtonDisabled}
          >
            {editing
              ? setDataSettled || inputHasError
                ? "Save"
                : ButtonMessage.PreparingTx
              : isPending
              ? ButtonMessage.ConfirmTx
              : isLoading
              ? ButtonMessage.PendingTx
              : "Edit"}
          </Button>
          {editing && (
            <Button variant="outlined" onClick={handleCancelButtonClick}>
              Cancel
            </Button>
          )}
        </Stack>
      )}
    </Stack>
  );
}
