import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { Keys, downloadKeys } from "../keys";
import { writeContract } from "@wagmi/core";
import { useSnackbar } from "notistack";
import { ButtonMessage, SnackbarMessage } from "../utils/texts";
import { useState } from "react";
import { config } from "../wagmi";

type ClaimIdentityDialogProps = {
  encryptionKeys: Keys;
  signatureKeys: Keys;
  identity: string;
  txRequest: any;
  onTxSubmit: (hash: `0x${string}`) => void;
  onCancel: () => void;
} & DialogProps;

export default function ClaimIdentityDialog({
  encryptionKeys,
  signatureKeys,
  identity,
  txRequest,
  onTxSubmit,
  onCancel,
  ...props
}: ClaimIdentityDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [checkboxChecked, setCheckboxChecked] = useState(false);

  function handleClickDownloadKeysButton() {
    downloadKeys({ encryptionKeys, signatureKeys, identity });
  }

  async function handleClickClaimIdentityButton() {
    setIsLoading(true);
    try {
      const txHash = await writeContract(config, txRequest);
      onTxSubmit(txHash);
      enqueueSnackbar({
        message: SnackbarMessage.TransactionSubmitted,
        variant: "info",
      });
      onCancel();
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Dialog {...props}>
      <Stack
        sx={{
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <DialogTitle>Identity registration</DialogTitle>
        <DialogActions>
          <IconButton onClick={onCancel} aria-label="close">
            <Close />
          </IconButton>
        </DialogActions>
      </Stack>
      <DialogContent>
        <Stack sx={{ gap: 2, alignItems: "center" }}>
          <Typography>
            Your keys file ({identity}.json) should be downloading
            automatically. If not, download them by clicking the link below:
          </Typography>
          <Button onClick={handleClickDownloadKeysButton}>Download keys</Button>
          <FormControlLabel
            control={
              <Checkbox
                checked={checkboxChecked}
                onChange={(event) => {
                  setCheckboxChecked(event.target.checked);
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="I have downloaded the keys. I understand that this is the last chance to download them."
          />
          <Button
            variant={!checkboxChecked || isLoading ? "outlined" : "contained"}
            size="large"
            onClick={handleClickClaimIdentityButton}
            disabled={!checkboxChecked || isLoading}
          >
            {isLoading ? ButtonMessage.ConfirmTx : `Register identity`}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
