import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { writeContract } from "@wagmi/core";
import { useSnackbar } from "notistack";
import { ButtonMessage, SnackbarMessage } from "../utils/texts";
import { useState } from "react";
import { config } from "../wagmi";

type UnclaimIdentityDialogProps = {
  identity: string;
  txRequest: any;
  onTxSubmit: (hash: `0x${string}`) => void;
  onCancel: () => void;
} & DialogProps;

export default function UnclaimIdentityDialog({
  identity,
  txRequest,
  onTxSubmit,
  onCancel,
  ...props
}: UnclaimIdentityDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [checkboxChecked, setCheckboxChecked] = useState(false);

  async function handleClickDeleteButton() {
    setIsLoading(true);
    try {
      const txHash = await writeContract(config, txRequest);
      onTxSubmit(txHash);
      enqueueSnackbar({
        message: SnackbarMessage.TransactionSubmitted,
        variant: "info",
      });
      onCancel();
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Dialog {...props}>
      <Stack
        sx={{
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <DialogTitle>Delete {identity}</DialogTitle>
        <DialogActions>
          <IconButton onClick={onCancel} aria-label="close">
            <Close />
          </IconButton>
        </DialogActions>
      </Stack>
      <DialogContent>
        <Stack sx={{ gap: 2, alignItems: "center" }}>
          <Typography>
            Deleting your identity will return your staked tokens, and burn your
            NFT. Your node will no longer be accessible for p2p.
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={checkboxChecked}
                onChange={(event) => {
                  setCheckboxChecked(event.target.checked);
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="I confirm I understand the consequences."
          />
          <Button
            variant={!checkboxChecked || isLoading ? "outlined" : "contained"}
            size="large"
            onClick={handleClickDeleteButton}
            disabled={!checkboxChecked || isLoading}
          >
            {isLoading ? ButtonMessage.ConfirmTx : `Delete`}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
