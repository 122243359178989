enum QueryKey {
  IDENTITIES = "IDENTITIES",
  IDENTITY = "IDENTITY",
  CLAIM_HISTORY = "CLAIM_HISTORY",
  BASE_REWARDS_RATE = "BASE_REWARDS_RATE",
  PROFILE_DATA = "PROFILE_DATA",
  SHINKAI_NAMESPACES = "SHINKAI_NAMESPACES",
  DEFAULT_SHINKAI_NAMESPACE = "DEFAULT_SHINKAI_NAMESPACE",
  URQL_CLIENT = "URQL_CLIENT",
}

export default QueryKey;
