"use client";
import { useQuery } from "@tanstack/react-query";
import QueryKey from "../utils/queryKeys";
import { gql } from "@urql/core";
import { useUrqlClient } from "./useUrqlClient";
import { BaseRewardsRate } from "../types";

type QueryResponse = {
  baseRewardsRates: BaseRewardsRate[];
};

const Query = gql<QueryResponse>`
  query {
    baseRewardsRates(first: 1, orderBy: timestamp, orderDirection: desc) {
      timestamp
      rate
    }
  }
`;

export const useGetBaseRewardsRate = () => {
  const { data: urqlData } = useUrqlClient();

  return useQuery({
    queryKey: [QueryKey.BASE_REWARDS_RATE, { urqlData }],
    queryFn: async () => {
      const urqlClient = urqlData?.client;
      if (!urqlClient) return null;
      const result = await urqlClient.query(Query, {});
      return result.data?.baseRewardsRates[0] ?? null;
    },
    staleTime: 60000,
  });
};
