"use client";
import { useAccount } from "wagmi";
import QueryKey from "../utils/queryKeys";
import { useQuery } from "@tanstack/react-query";
import { SHINKAI_NAMESPACES } from "../utils/constants";
import { env } from "../utils/envs";

export const useGetShinkaiNamespaces = () => {
  const { chainId } = useAccount();

  return useQuery({
    queryKey: [QueryKey.SHINKAI_NAMESPACES, { chainId }],
    queryFn: () =>
      SHINKAI_NAMESPACES[chainId ?? env.REACT_APP_DEFAULT_CHAIN_ID],
    initialData: {},
  });
};
