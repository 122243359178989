import { getShinkaiNftAddress, getShinkaiTokenAddress } from "./contracts";

export interface Token {
  symbol: string;
  decimals: number;
  image: string;
  address: string | "";
}

type UsedTokens = "KAI";

export const TOKENS: Record<UsedTokens, Token> = {
  KAI: {
    address: getShinkaiTokenAddress(),
    decimals: 18,
    symbol: "KAI",
    image: "/icon.png",
  },
};

export const canRegisterToken = () =>
  typeof window !== "undefined" && window?.ethereum?.isMetaMask;

export const addTokenToWallet = async (token: Token) => {
  try {
    await window.ethereum?.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20",
        options: {
          address: token.address,
          symbol: token.symbol,
          decimals: token.decimals,
          image: `${window.location.origin}${token.image}`,
        },
      },
    });
  } catch (err) {
    console.log(err);
  }
};

export const addNFTToWallet = async (tokenId: string) => {
  try {
    await window.ethereum?.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC721",
        options: {
          address: getShinkaiNftAddress(),
          tokenId,
        },
      },
    });
  } catch (err) {
    console.log(err);
  }
};
