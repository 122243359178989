import IdentityButton from "../components/IdentityButton";
import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import KaiTokenAmount from "./KaiTokenAmount";
import { Fragment, useCallback, useMemo, useState } from "react";
import { useDebounceCallback } from "usehooks-ts";
import { useGetIdentities } from "../hooks/useGetIdentities";

type Props = { owner?: string };

export function IdentityList({ owner }: Props) {
  const [filter, setFilter] = useState("");
  const [debouncedFilter, setDebouncedFilter] = useState("");

  const debounceFilterCallback = useCallback((value: string) => {
    setDebouncedFilter(value);
  }, []);

  const debounceFilter = useDebounceCallback(debounceFilterCallback, 500);
  const {
    data,
    isLoading,
    isError,
    isPlaceholderData,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useGetIdentities({ filter: debouncedFilter, owner });

  const handleFilterChange = (value: string) => {
    setFilter(value);
    debounceFilter(value);
  };

  const identities = useMemo(() => data?.pages?.flat(), [data]);

  return (
    <Stack sx={{ alignItems: "center", gap: 3 }}>
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
        onChange={(e) => {
          handleFilterChange(e.target.value);
        }}
        value={filter}
        fullWidth
        type="search"
        size="small"
        sx={{ maxWidth: "576px" }}
        placeholder="Search identity name..."
      />
      {isLoading || identities?.[0] === null ? (
        <CircularProgress />
      ) : isError ? (
        <Typography>Error occured when fetching identity list</Typography>
      ) : identities?.length === 0 ? (
        <Typography>No results.</Typography>
      ) : (
        <Stack gap={4} alignItems={"center"}>
          <Stack
            sx={{
              position: "relative",
            }}
          >
            <Grid
              container
              rowSpacing={2}
              columnSpacing={4}
              sx={{
                opacity: isPlaceholderData ? "0.3" : "1",
              }}
            >
              <Grid
                xs={8}
                sm={6}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <Typography>Identity</Typography>
              </Grid>
              <Grid
                xs={4}
                sm={6}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Typography>Staked KAI</Typography>
              </Grid>
              {identities?.map(
                (identity) =>
                  identity && (
                    <Fragment key={identity.identityRaw}>
                      <Grid
                        xs={8}
                        sm={6}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "end",
                        }}
                      >
                        <IdentityButton identityName={identity.identityRaw} />
                      </Grid>
                      <Grid
                        xs={4}
                        sm={6}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <KaiTokenAmount
                          amount={BigInt(identity.stakedTokens)}
                        />
                      </Grid>
                    </Fragment>
                  ),
              )}
            </Grid>
            {isPlaceholderData && (
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </Stack>

          {hasNextPage && (
            <Button
              onClick={() => fetchNextPage()}
              disabled={!hasNextPage || isFetchingNextPage}
            >
              {isFetchingNextPage
                ? "Loading more..."
                : hasNextPage
                ? "Load More"
                : "Nothing more to load"}
            </Button>
          )}
        </Stack>
      )}
    </Stack>
  );
}

export default IdentityList;
