import { bool, cleanEnv, json, num, str } from "envalid";

export const env = cleanEnv(process.env, {
  REACT_APP_TESTNET: bool(),
  REACT_APP_DEFAULT_CHAIN_ID: num(),
  REACT_APP_WALLET_CONNECT_PROJECT_ID: str(),
  REACT_APP_WAGMI_TRANSPORTS: json<Record<string, string>>(),
  REACT_APP_API_BASE_URLS: json<Record<string, string>>(),
  REACT_APP_DELEGATE_IDENTITY: json<Record<string, string>>(),
  REACT_APP_DELEGATE_PERCENTAGE: num(),
});
